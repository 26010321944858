<template>
  <div
    class="card card-custom bg-gray-100 gutter-b card-stretch card-shadowless"
  >
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <!--begin::Title-->
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder"
            >Evolução Consentimentos e Visualizações</span
          >
          <span class="d-block text-dark-50 mt-2 font-size-sm"
            >Mais de 500 novos consentimentos</span
          >
        </h3>
      </div>
      <!--end::Title-->
      <!--begin::Toolbar-->
      <div class="card-toolbar">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'month' }"
              @click="show = 'month'"
            >
              <span class="nav-text font-size-sm">Mês</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'week' }"
              @click="show = 'week'"
            >
              <span class="nav-text font-size-sm">Semana</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              data-toggle="tab"
              :class="{ active: this.show === 'day' }"
              @click="show = 'day'"
            >
              <span class="nav-text font-size-sm">Dia</span>
            </a>
          </li>
        </ul>
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Chart-->
      <div class="loading" v-if="loading">
        <div class="spinner spinner-primary mr-15"></div>
      </div>
      <div class="viewport">
        <apexchart
          :options="chartOptions"
          :series="graphData"
          type="bar"
        ></apexchart>
      </div>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
export default {
  name: "graph-evoluition",
  props: ["account"],
  components: {},
  data() {
    return {
      result: {},
      show: "day",
      loading: true
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    haveAccount() {
      return this.account ? `/account/${this.account}` : "";
    },
    consents() {
      let consents = {};
      this.result?.consents?.forEach(consent => {
        let index = this.makeIndex(consent._id);
        consents[index] = consent.count;
      });
      return consents;
    },
    views() {
      let views = {};
      this.result?.views?.forEach(view => {
        let index = this.makeIndex(view._id);
        views[index] = view.count;
      });
      return views;
    },
    graphData() {
      let consents = [];
      let views = [];
      this.result?.views?.forEach(view => {
        let alias = this.makeIndex(view._id);
        consents.push(this.consents[alias] || 0);
        views.push(view.count);
      });
      return [
        {
          name: "Consentimentos",
          data: consents
        },
        {
          name: "Visualizações",
          data: views
        }
      ];
    },
    xaxis() {
      let axis = [];
      this.result?.views?.forEach(view => {
        let alias = this.makeIndex(view._id);
        axis.push(alias);
      });
      return axis;
    },
    chartOptions() {
      return {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: ["30%"],
            endingShape: "rounded"
          }
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: this.xaxis,
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false
          },
          labels: {
            style: {
              colors: this.layoutConfig("colors.gray.gray-500"),
              fontSize: "12px",
              fontFamily: this.layoutConfig("font-family")
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: this.layoutConfig("colors.gray.gray-500"),
              fontSize: "12px",
              fontFamily: this.layoutConfig("font-family")
            }
          }
        },
        fill: {
          opacity: 1
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "none",
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0
            }
          }
        },
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          },
          y: {
            formatter: function(val) {
              return val + " ";
            }
          }
        },
        colors: [
          this.layoutConfig("colors.theme.base.success"),
          this.layoutConfig("colors.theme.base.danger")
        ],
        grid: {
          borderColor: this.layoutConfig("colors.gray.gray-200"),
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true
            }
          }
        }
      };
    }
  },
  watch: {
    show() {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  },

  methods: {
    makeIndex(item) {
      if (this.show == "day")
        return ` ${(`0` + item.day).slice(-2)}/${(`0` + item.month).slice(
          -2
        )}/${item.year}`;
      if (this.show == "week")
        return ` ${(`0` + item.week).slice(-2)}/${(`0` + item.month).slice(
          -2
        )}/${item.year}`;
      if (this.show == "month")
        return `${(`0` + item.month).slice(-2)}/${item.year}`;
    },
    getData() {
      ApiService.setHeader();
      this.loading = true;
      this.result = {};
      let userId = this.$route.query.user
        ? this.$route.query.user
        : this.$store.state.auth.user._id;
      let url = `/dashboard/${userId}/graph/${this.show}${this.haveAccount}`;
      ApiService.query(url)
        .then(({ data }) => {
          this.result = data;
          this.loading = false;
        })
        .catch(response => {
          console.log("USER ERROR", response);
        });
    }
  }
};
</script>
<style scoped>
.loading {
  height: 50%;
  position: absolute;
  width: 100%;
}
.loading div {
  position: relative;
  top: 50%;
  left: 50%;
}
.nav-link {
  cursor: pointer;
}
.viewport {
  position: relative;
  height: 350px;
}
.viewport > div {
  overflow: hidden;
  height: 350px !important;
  min-height: 350px !important;
}
.apexcharts-canvas {
  height: 350px !important;
}
</style>
