<template>
  <div class="card card-custom gutter-b" style="height: 200px;">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div
        class="d-flex align-items-center justify-content-between flex-grow-1"
      >
        <div class="mr-2">
          <h3 class="font-weight-bolder" :class="`text-${configs.style}`">
            {{ configs.title }}
          </h3>
          <div class="text-muted font-size-lg mt-2">
            {{ configs.description }}
          </div>
        </div>
        <div
          class="font-weight-boldest font-size-h1"
          :class="`text-${configs.style}`"
        >
          {{ pretty(configs.total) }}
        </div>
      </div>
      <div class="pt-8">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="text-muted font-weight-bold mr-2">
            {{ configs.subtitle }}: {{ pretty(configs.subtotal) }}
          </div>
          <div class="text-muted font-weight-bold">{{ percent }}%</div>
        </div>
        <div class="progress  progress-xs" :class="`bg-light-${configs.style}`">
          <div
            class="progress-bar"
            :class="`bg-${configs.style}`"
            role="progressbar"
            :style="`width: ${barVal}`"
            :aria-valuenow="percent"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import numeral from "numeral";
export default {
  name: "stats",
  props: ["configs"],
  created() {
    let interval = setInterval(() => {
      this.bar = this.percent;
      clearInterval(interval);
    }, 300);
  },
  methods: {
    pretty(number) {
      return numeral(number).format("0a");
    }
  },
  data() {
    return {
      bar: 0
    };
  },
  computed: {
    percent() {
      if (!this.configs.total || !this.configs.subtotal) return 0;
      return (
        ((this.configs.total / this.configs.subtotal) * 100).toFixed(2) || 0
      );
    },
    barVal() {
      return `${this.bar}%`;
    }
  }
};
</script>

<style></style>
