<template>
  <div>
    <!-- <div class="row">
      <template v-if="upgrade">
        <div class="col-xl-8">
          <Sessions></Sessions>
        </div>
        <div class="col-xl-4">
          <Upgrade :plan="nextPlan"></Upgrade>
        </div>
      </template>
      <template v-else>
        <div class="col-xl-12">
          <Sessions></Sessions>
        </div>
      </template>
    </div> -->
    <Navigation></Navigation>
    <div class="row" v-if="this.check('account')">
      <div class="col-xl-4">
        <stats :configs="consents"></stats>
      </div>
      <div class="col-xl-4">
        <stats :configs="accepted"></stats>
      </div>
      <div class="col-xl-4">
        <stats :configs="custom"></stats>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <GraphEvolution></GraphEvolution>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import Upgrade from "@/view/content/widgets/engage/upgrade.vue";
import GraphEvolution from "@/view/content/widgets/GraphEvolution";
// import Sessions from "@/view/content/widgets/list/Sessions.vue";
import stats from "@/view/content/widgets/stats.vue";
import { mapGetters } from "vuex";
import Navigation from "@/view/layout/navigation";
import ACL from "@/mixins/acl.js";

export default {
  name: "dashboard",
  mixins: [ACL],
  data() {
    return {
      stats: [],
      plansSequence: {
        1: "Plano Professional",
        2: "Plano Enterprise",
        3: "Você está no nosso maior plano"
      }
    };
  },
  components: {
    Navigation,
    // Upgrade,
    // Sessions,
    GraphEvolution,
    stats
  },
  computed: {
    ...mapGetters(["currentUser"]),
    upgrade() {
      return this.currentUser.plan.sequence < 3;
    },
    nextPlan() {
      return this.plansSequence[this.currentUser.plan.sequence];
    },
    consents() {
      return {
        style: "info",
        title: "Consentimentos",
        description: "Total de consentimentos",
        subtitle: "Total de visualizações",
        total: this.stats.consents,
        subtotal: this.stats.views
      };
    },
    accepted() {
      return {
        style: "success",
        title: "Aceitação",
        description: "Pessoas que aceitaram todos os cookies",
        subtitle: "Total de consentimentos",
        total: this.stats.all,
        subtotal: this.stats.consents
      };
    },
    custom() {
      return {
        style: "warning",
        title: "Personalizados",
        description: "Clientes que customizaram os cookies",
        subtitle: "Total de consentimentos",
        total: this.stats.custom,
        subtotal: this.stats.consents
      };
    }
  },
  mounted() {
    this.getAccounts();
    // this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    // console.log("STATE", this.$store.state.auth);
    // this.getAccounts();
  },
  methods: {
    getAccounts() {
      ApiService.setHeader();
      let userId = this.$route.query.user
        ? this.$route.query.user
        : this.$store.state.auth.user._id;
      ApiService.query(`/stats/user/${userId}`)
        .then(({ data }) => {
          //console.log("USER", data);
          // this.processList(data);
          this.stats = data[0];
        })
        .catch(() => {
          //console.log("USER ERROR", response);
        });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
